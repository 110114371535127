@import '~/ui/assets/styles/colors.module.scss';

.menu {
  margin: 20px 0;
  padding: 0;
  list-style-type: none;
  max-height: calc(100vh - 50px - 68px - 20px);
  overflow-y: scroll;
}

.closed {
  padding: 0;
}

.spacer {
  width: 17px;
}

.link {
  display: flex;
  align-items: center;
  padding: 13px 0;
  color: $color-grey-2;
  text-decoration: none;
  border-radius: 50px;
  margin-bottom: 10px;

  &:hover {
    background-color: $color-white-3;
    color: $color-red-2;
  }
}

.unreadCounter {
  position: absolute;
  z-index: 1;
  right: 15px;
  top: 15px;
}

.unreadMessages {
  min-width: 16px;
  height: 16px;
  border-radius: 8px;
  font-size: 0.69rem !important;
}

.unreadCounterCollapsed {
  top: 2px;
  right: 2px;
}

.collapsedLink {
  align-items: center;
  justify-content: center;
  border-radius: 0;
  margin-bottom: 10px;
}

.active {
  color: $color-red-2;
  background-color: $color-white-3;

  .icon {
    color: $color-red-2;
  }

  .title {
    color: $color-red-2;
  }

  &:hover {
    background-color: $color-white-3;
  }
}

.title {
  font-weight: 600;
  padding-left: 10px;
  font-size: 0.875rem;
}

.menuButton {
  justify-content: flex-end;
  display: flex;
  padding: 10px;
}

.menuButtonClosed {
  justify-content: center !important;
  display: flex;
}

.menuItem {
  position: relative;
}
